import React from 'react'
import { Link } from 'gatsby'

const Breadcrumb = props => {
  if (props.active) {
    return (
      <li className={["breadcrumb-item", props.active ? "active" : ""].join(" ")}>{props.name}</li>
    )
  }
  return (
    <li className="breadcrumb-item"><Link to={`/my-logue/category/${props.slug}`}>{props.name}</Link></li>
  )
}

export default Breadcrumb
